import { libraryLoaded, setClientName } from '@exp/exp-utils/helper/window';
import { getDocument, getWindow, isSupported } from '@exp/exp-utils/helper/browser';

import { log, error } from '@exp/exp-utils/helper/logger';

import { init as initConfigManager } from '@tcc/shared/src/helpers/configManager';
import { init as initEventSink } from '@tcc/shared/src/integrations/eventService/eventSend';
import { init as initDataLayer } from '@tcc/shared/src/interfaces/signalsDataLayer';
import { init as initTrafficDataLayer } from '@tcc/shared/src/interfaces/legacy/trafficDataLayer';

import { init as initEnv } from '@exp/exp-utils/helper/environment';
import SchemaHelper from '@exp/exp-utils/schema/schemaHelper';
import config from '../src/helpers/tcclConfig';
import loadHelper, { withCookieCache } from '@tcc/shared/src/helpers/load';
import interfaceSchema from '../../tccl/src/interfaces/interfaceSchema';
import VisitHelperTccl from './helpers/visitHelperTccl';
import * as tcclHandlers from './interfaces/legacy/trafficMethods';

import { getPerformanceData, observePerfMetrics } from '@tcc/shared/src/helpers/performance';

// Initialize the whole shootin match.  Note that order is important for data layer init
let initialized = false;

const _libraryName = 'tccl';

const _getEnv = () => {
  const baseHost = config.get(`${_libraryName}.baseHost`);
  if (!baseHost) {
    // route environment based on version of asset
    return config.get(`${_libraryName}.buildEnv`);
  }
  // route environment based on host override (legacy TCCL support)
  return baseHost === 'secureserver.net' ? 'prod' : // eslint-disable-line no-nested-ternary
    baseHost === 'test-secureserver.net' ? 'test' : 'dev';
};

const _tcclInit = () => {

  if (!initialized) {
    initialized = true;

    // Load order below is *IMPORTANT*

    // Kickoff perf metrics first so that we can capture entries which will fire early
    observePerfMetrics();

    // Delay push to Traffic sinks until load event
    loadHelper.init(
      getDocument().readyState === 'complete',
      (triggerOnLoad) => {
        getWindow().addEventListener('load', triggerOnLoad);
      }
    );

    // Populates visit GUIDs immediately if missing
    new VisitHelperTccl().getVisitInfo();

    // Traffic events will only be sent after the document has loaded.
    loadHelper.registerOnLoadFn(() => {
      initEventSink();
    });

    // Load Exp first as trfq commands will push to the SignalsDataLayer
    initDataLayer(new SchemaHelper(interfaceSchema), 'add_page_view', false);
    initTrafficDataLayer(tcclHandlers);

    // Only send performance data after the document has loaded
    if (config.get(`${_libraryName}.perfOn`)) {
      loadHelper.registerOnLoadFn(() => {
        getPerformanceData(_libraryName, 'auto');
      });
    }
    log('TCCL STARTED', config.getProperties());
  }
};

withCookieCache(() => {
  // Only load if the browser is supported
  if (isSupported()) {

    // Initialize window helper with library name
    setClientName(_libraryName);

    // Initialize configs so that we can determine if the library was disabled
    initConfigManager('_trfd', [_libraryName]);

    // Initialize environment so that we know where to route events
    initEnv(_getEnv());

    // Load library only once on page... Throw an error if attempted to load more then once
    if (libraryLoaded()) {
      error('TCCL Library has already been loaded on page');
    } else {
      // We should init only when visible
      const doc = getDocument();
      if (doc.visibilityState !== 'prerender') {
        _tcclInit();
      } else {
        doc.addEventListener('visibilitychange', () => {
          if (doc.visibilityState !== 'prerender' && doc.visibilityState !== 'unloaded') {
            _tcclInit();
          }
        });
      }
    }
  }
});
