import BaseEventHandler from './baseEventHandler';

class AddEventHandler extends BaseEventHandler {

  preProcess() {
    super.preProcess();
    this._setPageEventTcclProperties();
  }

  _setPageEventTcclProperties() {
    this.pageEvent.set('ht', 'pageevent');
    this.pageEvent.set('ea', this.data.ALL.type);
    this.pageEvent.set('eid', this.data.ALL.eid);
    if (typeof this.data.ALL.fields_object === 'object') {
      this.pageEvent.merge(this.data.ALL.fields_object);
    }
  }
}

export default AddEventHandler;
