import { PropertyDefinitionBlock, ObjectDefinitionBlock } from '@exp/exp-utils/schema/schemaDefinitionBlocks';

// Handlers
import AddEventHandler from './v1/handlers/addEvent';
import AddPageViewHandler from './v1/handlers/addPageView';
import AddPerfHandler from './v1/handlers/addPerf';

/*
For documentation on how to use the schema framework, refer to
https://github.secureserver.net/Experimentation/exp-utils/blob/master/test/schema/example/exampleSchema.js
*/

/*      Shared Object Schemas      */

const _allSchemaBlocks = () => {
  return [
    new PropertyDefinitionBlock('custom_properties')
      .optional()
  ];
};

const _eventObj = () => {
  return [
    new PropertyDefinitionBlock('eid').optional()
  ];
};

/*      Command Schemas     */

const commandSchemaDefinitions = {
  add_event: {
    v1: {
      handler: AddEventHandler,
      data: [
        new PropertyDefinitionBlock('type')
          .required(),
        new ObjectDefinitionBlock()
          .substitute(_eventObj()),
        new ObjectDefinitionBlock()
          .substitute(_allSchemaBlocks())
      ]
    }
  },

  add_page_view: {
    v1: {
      handler: AddPageViewHandler,
      data: [
        new PropertyDefinitionBlock('path')
          .optional(),
        new ObjectDefinitionBlock()
          .substitute(_eventObj()),
        new ObjectDefinitionBlock()
          .substitute(_allSchemaBlocks())
      ]
    }
  },

  add_perf: {
    v1: {
      handler: AddPerfHandler,
      data: [
        new PropertyDefinitionBlock('timing_object')
          .required(),
        new PropertyDefinitionBlock('is_hard_navigation')
          .optional(),
        new ObjectDefinitionBlock()
          .substitute(_allSchemaBlocks())
      ]
    }
  }
};

export default commandSchemaDefinitions;
