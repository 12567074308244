import BaseEventHandler from './baseEventHandler';

class AddPageViewHandler extends BaseEventHandler {

  preProcess() {
    super.preProcess();
    this._setPageViewTcclProperties();
  }

  _setPageViewTcclProperties() {
    this.pageEvent.set('ht', 'pageview');
    this.pageEvent.set('eid', this.data.ALL.eid);
    if (typeof this.data.ALL.fields_object === 'object') {
      this.pageEvent.merge(this.data.ALL.fields_object);
    }
  }

}

export default AddPageViewHandler;
