import { findCookie, setCookie } from '@exp/exp-utils/helper/cookie';
import { createGuid } from '@tcc/shared/src/helpers/guid';
import VisitHelper from '@tcc/shared/src/helpers/visitHelper';

class VisitHelperTccl extends VisitHelper {
  getVisitInfo() {
    var _tcclVisitorCookieName = '_tccl_visitor', _tcclVisitCookieName   = '_tccl_visit';
    var visitor = findCookie(_tcclVisitorCookieName);
    var visit   = findCookie(_tcclVisitCookieName);
    if (!visitor && !visit) {
      visitor = visit = createGuid();
    } else {
      if (!visitor) {
        visitor = createGuid();
      }
      if (!visit) {
        visit = createGuid();
      }
    }

    // create cookie or reset the expiration time
    setCookie(_tcclVisitorCookieName, visitor, 525600, '/');

    // create cookie or reset the expiration time
    setCookie(_tcclVisitCookieName, visit, 30, '/');

    return {
      visitorGuid: visitor,
      visitGuid: visit
    };
  }
}

export default VisitHelperTccl;
