import BaseEventHandler from './baseEventHandler';

class AddPerfHandler extends BaseEventHandler {

  preProcess() {
    super.preProcess();
    this._setPerfTcclProperties();
  }

  _setPerfTcclProperties() {
    this.pageEvent.set('ht', 'perf');

    const timingObject = this.data.ALL.timing_object || {};
    this.pageEvent.merge('navigationStart' in timingObject ? {
      tce: timingObject.connectEnd,
      tcs: timingObject.connectStart,
      tdc: timingObject.domComplete,
      tdclee: timingObject.domContentLoadedEventEnd,
      tdcles: timingObject.domContentLoadedEventStart,
      tdi: timingObject.domInteractive,
      tdl: timingObject.domLoading,
      tdle: timingObject.domainLookupEnd,
      tdls: timingObject.domainLookupStart,
      tfs: timingObject.fetchStart,
      tns: timingObject.navigationStart,
      trqs: timingObject.requestStart,
      tre: timingObject.responseEnd,
      trps: timingObject.responseStart,
      tles: timingObject.loadEventStart,
      tlee: timingObject.loadEventEnd,
      nt: timingObject.navigationType,
      lcp: timingObject.largestContentfulPaint
    } : timingObject);

    this.data.ALL.is_hard_navigation ?
      this.pageEvent.set('nav_type', 'hard') :
      this.pageEvent.set('nav_type', 'soft');
  }
}

export default AddPerfHandler;
